// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MenuModal_TJpqM{background:#09040d;display:flex;flex-direction:column;height:100%;justify-content:space-between;padding:3.2rem 1.6rem}.item_d0jlu{cursor:pointer}.item_d0jlu:not(:last-of-type){margin-bottom:2.4rem}.link_xB5yJ{font-family:\"Bebas-Neue\",sans-serif;font-size:3.6rem;font-weight:700;letter-spacing:-.02em;line-height:3.6rem;transition:color .3s ease}.link_xB5yJ._active_Lslpz,.link_xB5yJ._active-link,.link_xB5yJ._exact-link{color:#9d67f9}.link_xB5yJ sup{color:#848286;font-size:1.8rem;line-height:1.8rem;top:-1.5rem}.socialBtns_PEK67{margin-bottom:2.4rem}.socialBtn_iSg9q:not(:last-of-type){margin-right:.4rem}.btn_Cy8Wt{width:100%}.btn_Cy8Wt._schelude_mRYm3{margin-bottom:1.2rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"MenuModal": "MenuModal_TJpqM",
	"item": "item_d0jlu",
	"link": "link_xB5yJ",
	"_active": "_active_Lslpz",
	"socialBtns": "socialBtns_PEK67",
	"socialBtn": "socialBtn_iSg9q",
	"btn": "btn_Cy8Wt",
	"_schelude": "_schelude_mRYm3"
};
module.exports = ___CSS_LOADER_EXPORT___;
