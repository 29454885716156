
// scss
import scssVars from '~/assets/scss/shared/_variables.scss';

// Vuex
import { mapActions, mapState } from 'vuex';

// Utils
import { leadingZero } from '@@/common/assets/js/utils/numbers-utils';
import { remToPx, scrollToWithBlackout } from '@@/common/assets/js/utils/common-utils';

// Constants
import { menuItems } from '~/assets/js/constants/menuItems';

const ITEM_TEMPLATE = {
    buttons: {
        join: 'Вступить в клуб',
        schedule: 'Скачать расписание',
    },
};

export default {
    name: 'MenuModal',

    props: {
        scheduleLink: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            menuItems,
            text: ITEM_TEMPLATE,

            links: [
                {
                    icon: 'vk',
                    link: 'https://vk.com/',
                },
                {
                    icon: 'tg',
                    link: 'https://t.me/',
                },
                {
                    icon: 'youtube',
                    link: 'https://youtube.com/',
                },
            ],
        };
    },

    computed: {
        ...mapState('header', [
            'activeMenuItemId',
        ]),

        isOnHome() {
            return this.$route.path === '/';
        },

        currentForm() {
            const formDevelopers = 'home-form-developers';
            const homeForm = 'home-form';
            return this.isOnHome ? homeForm : formDevelopers;
        },
    },

    methods: {
        ...mapActions('header', [
            'changeBurgerMenuState',
            'changeActiveMenuItemId',
        ]),

        handleClickLink(item) {
            if (!item.link && !this.isOnHome) {
                this.changeActiveMenuItemId(item.id);
                this.$router.push('/');
            } else {
                item.link ? this.changeActiveMenuItemId(item.id) : this.scrollToId(item);
            }
            this.changeBurgerMenuState(false);
        },

        addLeadingZero(index) {
            return leadingZero(index);
        },

        scrollToId(item) {
            const headerHeight = remToPx(scssVars[this.$deviceIs.pc ? 'header-h' : 'header-h-mobile']);
            const offset = item.id.includes('purpose') ? window.innerHeight * -1 : headerHeight;

            this.changeBurgerMenuState(false);
            scrollToWithBlackout(item.id, offset);
            this.changeActiveMenuItemId(item.id);
        },

        scrollToForm() {
            scrollToWithBlackout(this.currentForm);
            this.changeBurgerMenuState(false);
        },
    },
};
