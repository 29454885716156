
// Vuex
import { mapActions, mapGetters } from 'vuex';

// Components
import TheModal from '~/components/layout/TheModal';
import TheFooter from '../components/layout/TheFooter';
import TheHeader from '~/components/layout/TheHeader.vue';
import TheMenu from '~/components/layout/TheMenu';
import TheCursor from '~/components/layout/TheCursor.vue';

export default {
    name: 'DefaultLayout',
    components: {
        TheCursor,
        TheMenu,
        TheHeader,
        TheModal,
        TheFooter,
    },

    data() {
        return {
            schedule: {},
        };
    },

    head() {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: `${this.siteDomain}${this.$route.path}`,
                },
            ],

            meta: [
                {
                    hid: 'og:type',
                    name: 'og:type',
                    content: 'website',
                },
                {
                    hid: 'og:url',
                    name: 'og:url',
                    content: `${this.siteDomain}${this.$route.path}`,
                },
            ],
        };
    },

    computed: {
        ...mapGetters({
            siteDomain: 'domain/getDomainAddress',
        }),

        showHeader() {
            return this.$route.path === '/' || this.$route.path === '/developers';
        },

        scheduleLink() {
            return this.schedule?.schedule_file || this.schedule?.schedule_link;
        },
    },

    mounted() {
        this.$nextTick(() => {
            const touchSupported = 'ontouchstart' in window ||
                navigator.maxTouchPoints > 0 ||
                navigator.msMaxTouchPoints > 0;

            this.setTouchSupport(touchSupported);
        });
        this.getSchedule();
    },

    methods: {
        ...mapActions({
            setTouchSupport: 'device/changeIsTouch',
        }),


        async getSchedule() {
            try {
                const schedule = await this.$axios.$get(this.$api.documents.schedule);
                this.schedule = schedule ? schedule : {};
            } catch (e) {
                console.warn('[defaultLayout/asyncData] request failed: ', e);
            }
        },
    },
};
