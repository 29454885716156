
// Vuex
import { mapState } from 'vuex';

// Utils
import { disablePageScroll, enablePageScroll } from 'scroll-lock/dist/scroll-lock';

// Components
import MenuModal from '~/components/modals/MenuModal';

export default {
    name: 'TheMenu',

    components: {
        MenuModal,
    },

    props: {
        scheduleLink: {
            type: String,
            default: '',
        },
    },

    computed: {
        ...mapState({
            isMenuOpen: state => state.header.isBurgerMenuVisible,
        }),
    },

    watch: {
        isMenuOpen() {
            if (this.isMenuOpen) {
                disablePageScroll();
            } else {
                enablePageScroll();
            }
        },
    },
};
